import React, {useEffect, useState} from "react";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import {BottomErrMsg} from "component/basic/popup";
import {InputBoxCol2, InputItemBox} from "../../../component/basic/formItems";
import * as fatchSet from "../../../api/api";
import {useNavigate, useParams} from "react-router-dom";
import useGet from "../../../api/useGet";

const Modify = (props) => {
    let navigate = useNavigate();

    const id = useParams().id;

    const [loginId, setLoginId] =  useState('');
    const [password, setPassword] =  useState('');
    const [passwordConfirm, setPasswordConfirm] =  useState('');
    const [name, setName] =  useState('');
    const [phone, setPhone] =  useState('');

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const accountApi = useGet({url:`/employee/detail?user_id=${id}`, loginType:"login"});

    useEffect(() => {
        setLoginId(accountApi?.data?.employee?.login_id)
        setName(accountApi?.data?.employee?.name)
        setPhone(accountApi?.data?.employee?.phone)
    }, [accountApi]);

    const handleModify = async () => {
        if ((password || passwordConfirm) && password !== passwordConfirm) {
            setBottomMsgData({
                text : "새 비밀번호 값이 일치하지 않습니다.",
                chk : bottomMsgData.chk + 1
            });
            return false
        }

        const formdata = new FormData();
        formdata.append("user_id", id);
        if (password) {
            formdata.append("password", password);
        }
        formdata.append("login_id", loginId);
        formdata.append("name", name);
        formdata.append("phone", phone);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formdata,
            url: "/employee/modify",
            loginType: "login",
            success: (data) => {
                setBottomMsgData({
                    text : "저장되었습니다",
                    chk : bottomMsgData.chk + 1
                });
                navigate('/settings/account?tab=employee')
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    };

    const handleDelete = async () => {
        fatchSet.FatchApi({
            type:"DELETE",
            loginType:"login",
            url: `/employee/delete?user_id=${id}`,
            success: (data) => {
                setBottomMsgData({
                    text : "삭제되었습니다",
                    chk : bottomMsgData.chk + 1
                });
                navigate('/settings/account?tab=employee')
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    };

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={"gBg"}
            >
                <LeftMenu headTitle="직원 상세"/>
                <div className="contents">
                    <Header/>
                    <div className="contentsBox">
                        <div className="menuTitleBox">
                            <div className="title">직원 상세</div>
                            <div className="buttonBoxs nonMbtn">
                                <button className="dangerButton ghost" onClick={() => handleDelete()}>직원 삭제</button>
                                <button className="primaryButton ghost" onClick={() => navigate('/settings/account?tab=employee')}>뒤로가기</button>
                                <button className="primaryButton" onClick={() => handleModify()}>저장하기</button>
                            </div>
                        </div>
                        <div className="contentsItem">
                            <div className="inputBox">
                                <InputItemBox
                                    inputType={null}
                                    addClass=""
                                    inputName="아이디"
                                    placeholder="아이디를 입력해주세요"
                                    value={loginId}
                                    func={(value)=>{setLoginId(value)}}
                                    max={null}
                                />
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="직원명"
                                        placeholder="직원 성함을 입력해주세요."
                                        value={name}
                                        func={(value)=>{setName(value)}}
                                    />
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="직원 휴대폰 번호"
                                        placeholder="직원 휴대폰 번호를 입력해주세요."
                                        value={phone}
                                        func={(value)=>{setPhone(value)}}
                                    />
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType="password"
                                        addClass=""
                                        inputName="새 비밀번호"
                                        placeholder="새 비밀번호를 입력해주세요"
                                        value={password}
                                        func={(value)=>{setPassword(value)}}
                                    />
                                    <InputItemBox
                                        inputType="password"
                                        addClass=""
                                        inputName="새 비밀번호 확인"
                                        placeholder="새 비밀번호 확인을 입력해주세요"
                                        value={passwordConfirm}
                                        func={(value)=>{setPasswordConfirm(value)}}
                                    />
                                </InputBoxCol2>
                            </div>
                            <div className="mButtonBoxs">
                                <button className="dangerButton ghost" onClick={() => handleDelete()}>직원 삭제</button>
                                <button className="primaryButton ghost" onClick={() => navigate('/settings/account?tab=employee')}>뒤로가기</button>
                                <button className="primaryButton" onClick={() => handleModify()}>저장하기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Modify;
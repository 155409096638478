import {CustomSelect, DatePicer, DatePicerRange} from "../basic/formItems";
import React from "react";
import moment from "moment";

function MenuTitle(data){
    return (
        <div className="menuTitleBox">
            <div className={`title ${data?.titleAddClass}`}>{data?.title}</div>
            <div className={`buttonBoxs ${data?.buttonBoxAddClass}`}>
                {data?.useDate && (
                    <DatePicer
                        inputType="text"
                        addClass="noBorder searchDatePicker"
                        boxAddClass={'inputGroup'}
                        placeholder="날짜 선택"
                        monthFunc={(date) => {}}
                        value={data?.date}
                        max={null}
                        regexp={null}
                        func={(date)=> { data?.setDate(date ? moment(date).format('YYYY-MM-DD') : ''); }}
                    />
                )}
                {data?.useDateRange && (
                    <DatePicerRange
                        inputType="text"
                        addClass="searchDateRangePicker noBorder"
                        boxAddClass={'inputGroup'}
                        placeholder="날짜 선택"
                        monthFunc={(date) => {}}
                        beginDate={data?.beginDate}
                        endDate={data?.endDate}
                        value={data?.beginDate + ' ~ ' + data?.endDate}
                        max={null}
                        regexp={null}
                        func={(beginDate, endDate)=> { data?.setBeginDate(beginDate ? moment(beginDate).format('YYYY-MM-DD') : ''); data?.setEndDate(endDate ? moment(endDate).format('YYYY-MM-DD') : ''); }}
                    />
                )}
                {data?.useSelectType && (
                    <CustomSelect
                        inputType=""
                        addClass="noBorder"
                        boxAddClass="m-0"
                        inputName=""
                        placeholder="기준"
                        value={data?.selectType}
                        func={(name, value)=>{data?.setSelectType(value)}}
                        valKey="id"
                        nameKey="type"
                        options={[
                            {id: "hour", type: "시간 기준"},
                            {id: "day", type: "일 기준"},
                            {id: "week", type: "주 기준"},
                            {id: "month", type: "월 기준"},
                            {id: "year", type: "연 기준"},
                        ]}
                    />
                )}
                {data?.buttons?.map((button) => (
                    <button className={`buttonBox ${button?.tab === data?.tab ? 'active' : ''}`} onClick={() => {data?.func(button?.tab)}}>{button?.name}</button>
                ))}
            </div>
        </div>
    );
}

export {MenuTitle};
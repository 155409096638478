import React, {useRef, useState} from "react";
import { useSearchParams } from 'react-router-dom';
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import {CustomPopup, LoadingBox} from "component/basic/popup";
import { BtnBox, BtnItem } from "component/basic/btns";
import { MenuTitle } from "../../component/menu/menuTitle";
import Download from "./download/Index";
import Overall from "./overall/Index";
import ReportPdfDownload from "../../component/report/ReportPdfDownload";
import OverallPdfDownload from "../../component/report/OverallPdfDownload";

const Index = (props) => {
    const overallPdfRef = useRef();
    const pdfRef = useRef();

    const [searchParams] = useSearchParams();

    const [tab, setTab] =  useState(searchParams.get('tab') ? searchParams.get('tab') : 'employee');
    const [customPopupData, setCustomPopupData] =  useState(null);
    const [isVisible, setIsVisible] =  useState(false);
    const [loading, setLoading] =  useState(false);

    const handleClick = (downloadId, startDate, endDate, time) => {
        if (pdfRef.current) {
            pdfRef.current.downloadPdf(downloadId, startDate, endDate, time);
        }
    };

    const handleOverallClick = (downloadId) => {
        if (pdfRef.current) {
            overallPdfRef.current.downloadPdf(downloadId);
        }
    };

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={"gBg"}
            >
                <LeftMenu headTitle="Reports"/>
                <div className="contents">
                    <Header/>
                    <div className="contentsBox">
                        <MenuTitle title="Reports" tab={tab} buttons={[{name: "다운로드 내역", tab: "download"}, {name: "Overall Review", tab: "overall"}]} func={(e) => {setTab(e)}}/>
                        {tab === 'employee' && <Download handleClick={handleClick}/>}
                        {tab === 'overall' && <Overall handleClick={handleOverallClick}/>}
                    </div>
                </div>
                <LoadingBox openType={loading}/>
            </ContentsSection>
            <CustomPopup data={customPopupData}>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass="line"
                        contents="취소"
                        func={() => {setCustomPopupData(null)}}
                    />
                </BtnBox>
            </CustomPopup>
            <OverallPdfDownload ref={overallPdfRef} loading={loading} setLoading={setLoading} />
            <ReportPdfDownload ref={pdfRef} loading={loading} setLoading={setLoading} />
        </>
    );
};

export default Index;
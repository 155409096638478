import { getStyle } from "js/function";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

function DataCaption(data){
    return (
        <p className={`dataCaption ${data.color||""} ${data.addClass||""}`} dangerouslySetInnerHTML={{__html:data.type}}/>
    );
}

function DataBtn(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`dataBtn ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false}>
            {data.children}
            <img src="/assets/images/basic/move_min.svg"/>
        </button>
    );
}

function FixedSection(data){
    const fixedTargetRef = useRef(null);
    const fixedBoxRef = useRef(null);
    const [fixedCategory, setFixedCategory] = useState("");

    function handleScroll(){
        if(data.minSize ? window.innerWidth >= data.minSize : true){
            let header = document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0,
                footer = data.footerChk && document.querySelectorAll(".footer")[0] ? document.querySelectorAll(".footer")[0].offsetTop : null,
                targetHeight = fixedTargetRef?.current?.clientHeight,
                pageBox = getStyle(document.querySelectorAll(`.${data.pageBox}`)[0], "paddingBottom","number");

            if(footer ? window.scrollY + header + targetHeight > footer - Number(pageBox) - 40 : false){
                setFixedCategory("hdFtFixedItem");
            }else if(data.headerChk ? window.scrollY + header > fixedBoxRef?.current?.getBoundingClientRect().top : window.scrollY > fixedBoxRef?.current?.getBoundingClientRect().top){
                setFixedCategory(data.headerChk ? "headerFixedItem" : "fixedItem");
            }else{
                setFixedCategory("");
            }
            
            if(data.contentsBox && document.querySelectorAll(`.${data.contentsBox}`)[0]){
                document.querySelectorAll(`.${data.contentsBox}`)[0].style.minHeight = fixedTargetRef?.current?.clientHeight ? `${fixedTargetRef?.current?.clientHeight + 100}px` : "60vh"
            }
        }
    };

    useEffect(() => {
        handleScroll();
        const timer = setInterval(() => {
            window.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", handleScroll);
        }, 100);
        return () => {
            clearInterval(timer);
            window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleScroll);
        };
    }, []);

    return (
        <div className={`fixedPoint ${data.boxAddClass ? data.boxAddClass : ""}`} ref={fixedBoxRef}>
            <div className={`fixedSection ${data.addClass ? data.addClass : ""} ${data.notFixed ? "" : fixedCategory}`} style={(fixedCategory === "headerFixedItem" || fixedCategory === "fixedItem") && !data.notFixed ? {top:`calc(${Number(document.querySelectorAll(".header")[0] ? document.querySelectorAll(".header")[0].clientHeight : 0)}px + 3.13em)`} : {}} ref={fixedTargetRef}>{data.children}</div>
        </div>
    );
}

function DotText(data){
    return (
        <div className={`dotTextBox ${data.addClass||""}`}>
            <span/>
            <p className="dotText" dangerouslySetInnerHTML={{__html:data.text}}/>
        </div>
    );
}


function TableSection(data){
    return (
        <div className={`tableSection ${data.addClass||""}`}>
            {data.headTitle && (<div className="headTitle">{data?.headTitle}</div>)}
            {data.headType ? 
                <div className={`table_head ${data.headAddClass||""}`}>
                    {data.th?.map((item,i)=>(
                        <div className="table_th" key={i} style={item.style ? item.style : {}}><div className='table_th_contents'>{item.contents}</div></div>
                    ))}
                </div>
            :""}
            <div className="table_body">
                {data.children}
            </div>
        </div>
    );
}

function TableTr(data){
    return (
        <div className={`table_tr ${data.addClass||""}`} style={data.style ? data.style : {}} onClick={(e) => data?.func(e)} onMouseEnter={() => data?.mouseEnterFunc()} onMouseLeave={() => data?.mouseLeaveFunc()}>
            {data.children}
        </div>
    );
}

function TableTd(data){
    return (
        <div className={`table_td ${data.addClass||""}`} onClick={()=>{if(data.func)data.func()}} style={data.style ? data.style : {}}>
            {data.children}
        </div>
    );
}

function TableTextItem(data){
    return (
        <p className="tableTextItem" style={data.style ? data.style : {}} dangerouslySetInnerHTML={{__html:data.text}}/>
    );
}

function TableBasicTextItem(data){
    return (
        <p className={`tableBasicTextItem ${data.addClass||""}`} style={data.style ? data.style : {}} dangerouslySetInnerHTML={{__html:data.text}}/>
    );
}

function TableTextValItem(data){
    return (
        <div className={`tableTextValItem ${data.addClass||""} ${data.mName ? "mName" : ""}`} style={data.style ? data.style : {}}>
            <p className={`tableTextValItem_name ${data.mName ? "mContents" : ""}`} dangerouslySetInnerHTML={{__html:data.name}}/>
            <p className="tableTextValItem_val" dangerouslySetInnerHTML={{__html:data.val}}/>
        </div>
    );
}

function TableMinBtn(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`tableMinBtn ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false}>
            {data.children}
        </button>
    );
}

function PopupInputBox(data){
    return (
        <div className={`popupInputBox ${data.addClass||""}`} style={data.style ? data.style : {}}>
            {data.children}
        </div>
    );
}

function SubInfoTextSection(data){
    return (
        <div className={`subInfoTextSection ${data.addClass||""}`} style={data.style ? data.style : {}}>
            {data.children}
        </div>
    );
}

function SubInfoTextBox(data){
    return (
        <div className={`subInfoTextArea ${data.addClass||""} ${data.img ? "subInfoTextArea_img" : ""}`}>
            {data.img ? <img src={data.img}/> : ""}
            <div className="subInfoTextBox">
                {data.title ? <h1 className="subInfoTextTitle" dangerouslySetInnerHTML={{__html:data.title}}/> : ""}
                <div className="subInfoText_contents">
                    {data.text ? <p className="subInfoText" dangerouslySetInnerHTML={{__html:data.text}}/> : ""}
                    {data.textArr ? 
                    <>
                        {data.textArr?.map((item,i)=>(
                            <div className="subInfoText_valueItem" key={i}>
                                <p className="subInfoText_valueItem_name" dangerouslySetInnerHTML={{__html:item.name}}/> 
                                <p className="subInfoText_valueItem_val" dangerouslySetInnerHTML={{__html:item.val}}/> 
                            </div>
                        ))}
                    </>
                    :""}
                    {data.children}
                </div>
            </div>
        </div>
    );
}

export {DataCaption,DataBtn,FixedSection,DotText,TableSection,TableTr,TableTd,TableTextItem,TableBasicTextItem,TableTextValItem,TableMinBtn,PopupInputBox,SubInfoTextSection,SubInfoTextBox};
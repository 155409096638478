import { useLocation, useNavigate } from "react-router-dom";
import useGet from "../../api/useGet";
import { bodyScroll_clear, bodyScroll_limit } from "js/function";
import {useEffect, useState} from "react";

function LeftMenu(headerData){
    let navigate = useNavigate();
    const location = useLocation();

    const [menuOpen,setMenuOpen] = useState(false);

    useEffect(() => {
        if(menuOpen){
            bodyScroll_limit();
        }else{
            bodyScroll_clear();
        }
    }, [menuOpen]);

    function closePopup(){
        bodyScroll_clear();
        setMenuOpen(false);
    }

    return (
        <div className="leftMenu">
            <div className="pcLeftMenu">
                <div className="top">
                    <img className="logo" src="/assets/images/logo.png"/>
                    <div className={"menu " + (location?.pathname?.includes('/insight') ? 'active' : '')} onClick={() => navigate('/insight')}><img src="/assets/images/icon/insight.svg"/>Insight</div>
                    <div className="col2">
                        <div className={"menu " + (location?.pathname?.includes('/sensor/tree') ? 'active' : '')} onClick={() => navigate('/sensor/tree')}><img src="/assets/images/icon/tree.svg"/>Tree <br/>Health</div>
                        <div className={"menu " + (location?.pathname?.includes('/sensor/soil') ? 'active' : '')} onClick={() => navigate('/sensor/soil')}><img src="/assets/images/icon/soil.svg"/>Soil <br/>Condition</div>
                    </div>
                    <div className={"menu " + (location?.pathname?.includes('/field') ? 'active' : '')} onClick={() => navigate('/field')}><img src="/assets/images/icon/field.svg"/>Field Data</div>
                    <div className={"menu " + (location?.pathname?.includes('/reports') ? 'active' : '')} onClick={() => navigate('/reports')}><img src="/assets/images/icon/reports.svg"/>Reports</div>
                    <div className="col2">
                        <div className={"menu " + (location?.pathname?.includes('/help') ? 'active' : '')} onClick={() => window.open('https://google.com')}><img src="/assets/images/icon/help.svg"/>Help</div>
                        <div className={"menu " + (location?.pathname?.includes('/settings') ? 'active' : '')} onClick={() => navigate('/settings/account')}><img src="/assets/images/icon/setting.svg"/>Settings</div>
                    </div>
                </div>
                <div className="bottom">
                    <button className="footerButton" onClick={() => window.open('https://google.com')}>Emergency Service Request</button>
                </div>
            </div>
            <div className="mLeftMenu">
                <div className={`headerSection ${headerData.addClass ? headerData.addClass : ""}`}>
                    <button type="button" onClick={()=>setMenuOpen(true)} className={`btnHeaderLeft btnMenuOpen ${menuOpen ? "active" : ""}`}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <p className={`headTitle centerTitle`} dangerouslySetInnerHTML={{__html:headerData.headTitle}}/>
                </div>
                <div className={`sideCover ${menuOpen ? "active" : ""}`} onClick={()=>{closePopup()}}></div>
                <div className={`sideMenu ${menuOpen ? "active" : ""}`}>
                    <div className="top">
                        <div className={`headerSection ${headerData.addClass ? headerData.addClass : ""}`} style={{ height: "15%" }}>
                            <button type="button" onClick={()=>setMenuOpen(!menuOpen)} className={`btnHeaderLeft btnMenuOpen ${menuOpen ? "active" : ""}`}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                            <button type="button" className="btnLogo" onClick={(e) => { navigate("/"); setMenuOpen(false) }}><img src="/assets/images/logo.png"/></button>
                        </div>
                        <div className={"menu " + (location?.pathname?.includes('/insight') ? 'active' : '')} onClick={() => { navigate('/insight'); setMenuOpen(false) }}><img src="/assets/images/icon/insight.svg"/>Insight</div>
                        <div className="col2">
                            <div className={"menu " + (location?.pathname?.includes('/sensor/tree') ? 'active' : '')} onClick={() => { navigate('/sensor/tree'); setMenuOpen(false) }}><img src="/assets/images/icon/tree.svg"/>Tree <br/>Health</div>
                            <div className={"menu " + (location?.pathname?.includes('/sensor/soil') ? 'active' : '')} onClick={() => { navigate('/sensor/soil'); setMenuOpen(false) }}><img src="/assets/images/icon/soil.svg"/>Soil <br/>Condition</div>
                        </div>
                        <div className={"menu " + (location?.pathname?.includes('/field') ? 'active' : '')} onClick={() => { navigate('/field'); setMenuOpen(false) }}><img src="/assets/images/icon/field.svg"/>Field Data</div>
                        <div className={"menu " + (location?.pathname?.includes('/reports') ? 'active' : '')} onClick={() => { navigate('/reports'); setMenuOpen(false) }}><img src="/assets/images/icon/reports.svg"/>Reports</div>
                        <div className="col2">
                            <div className={"menu " + (location?.pathname?.includes('/help') ? 'active' : '')} onClick={() => window.open('https://google.com')}><img src="/assets/images/icon/help.svg"/>Help</div>
                            <div className={"menu " + (location?.pathname?.includes('/settings') ? 'active' : '')} onClick={() => { navigate('/settings/account'); setMenuOpen(false) }}><img src="/assets/images/icon/setting.svg"/>Settings</div>
                        </div>
                    </div>
                    <div className="bottom">
                        <button className="footerButton" onClick={() => window.open('https://google.com')}>Emergency Service Request</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export {LeftMenu};
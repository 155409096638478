import React, { useState } from "react";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import {BottomErrMsg} from "component/basic/popup";
import {InputBoxCol2, InputItemBox} from "../../../component/basic/formItems";
import * as fatchSet from "../../../api/api";
import {useNavigate} from "react-router-dom";

const Register = (props) => {
    let navigate = useNavigate();

    const [loginId, setLoginId] =  useState('');
    const [password, setPassword] =  useState('');
    const [name, setName] =  useState('');
    const [phone, setPhone] =  useState('');

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const handleRegister = async () => {
        const formdata = new FormData();
        formdata.append("login_id", loginId);
        formdata.append("password", password);
        formdata.append("name", name);
        formdata.append("phone", phone);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formdata,
            url: "/employee/register",
            loginType: "login",
            success: (data) => {
                setBottomMsgData({
                    text : "등록되었습니다",
                    chk : bottomMsgData.chk + 1
                });
                navigate('/settings/account?tab=download')
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    };

    return (
        <>
            <ContentsSection
                header={true}
                footer={true}
                addClass={"gBg"}
            >
                <LeftMenu headTitle="직원 등록"/>
                <div className="contents">
                    <Header/>
                    <div className="contentsBox">
                        <div className="menuTitleBox">
                            <div className="title">직원 등록</div>
                            <div className="buttonBoxs nonMbtn">
                                <button className="primaryButton ghost" onClick={() => navigate('/settings/account?tab=download')}>뒤로가기</button>
                                <button className="primaryButton" onClick={() => handleRegister()}>등록하기</button>
                            </div>
                        </div>
                        <div className="contentsItem">
                            <div className="inputBox">
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="아이디"
                                        placeholder="로그인 시 사용할 아이디를 입력해주세요."
                                        value={loginId}
                                        func={(value)=>{setLoginId(value)}}
                                    />
                                    <InputItemBox
                                        inputType="password"
                                        addClass=""
                                        inputName="비밀번호"
                                        placeholder="로그인 시 사용할 비밀번호를 입력해주세요."
                                        value={password}
                                        func={(value)=>{setPassword(value)}}
                                    />
                                </InputBoxCol2>
                                <InputBoxCol2>
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="직원명"
                                        placeholder="직원 성함을 입력해주세요."
                                        value={name}
                                        func={(value)=>{setName(value)}}
                                    />
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="직원 휴대폰 번호"
                                        placeholder="직원 휴대폰 번호를 입력해주세요."
                                        value={phone}
                                        func={(value)=>{setPhone(value)}}
                                    />
                                </InputBoxCol2>
                            </div>
                            <div className="mButtonBoxs">
                                <button className="primaryButton ghost" onClick={() => navigate('/settings/account?tab=employee')}>뒤로가기</button>
                                <button className="primaryButton" onClick={() => handleRegister()}>등록하기</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Register;
import React, {Fragment, useEffect, useState} from "react";
import { ContentsSection } from "component/app/items";
import { Header } from "component/elements/header";
import { LeftMenu } from "component/elements/leftMenu";
import moment from "moment/moment";
import {CustomSelect, InputItemBox} from "../../component/basic/formItems";
import {useParams} from "react-router-dom";
import useGet from "../../api/useGet";
import {TableSection, TableTd, TableTr} from "../../component/basic";
import PageNation from "../../component/basic/pageNation";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, Legend, LinearScale, LineElement, PointElement, Title, Tooltip } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import {MenuTitle} from "../../component/menu/menuTitle";

const Detail = (props) => {
    const id = useParams().id;

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend,
        annotationPlugin
    );

    const [page, setPage] =  useState(1);
    const [chartType, setChartType] =  useState('hour');
    const [startDate, setStartDate] =  useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] =  useState(moment().format('YYYY-MM-DD'));
    const [tab, setTab] =  useState(null);
    const [chartData, setChartData] =  useState({labels: [], datasets: [{label: "", data: []}],});

    const sensorApi = useGet({url:`/sensor/detail?sensor_id=${id}&data_code=${tab ? tab?.code : ''}&page=${page}`, loginType:"login"});
    const sensorChartApi = useGet({url:`/sensor/chart?sensor_id=${id}&type=${chartType}&data_code=${tab ? tab?.code : ''}&start_date=${startDate}&end_date=${endDate}`, loginType:"login"});

    useEffect(() => {
        if (!tab && sensorApi && sensorApi?.data?.sensor_data_details?.length > 0) {
            setTab({"code": sensorApi?.data?.sensor_data_details[0]?.code, "name": sensorApi?.data?.sensor_data_details[0]?.name})
        }
    }, [sensorApi]);

    useEffect(() => {
        const labels = sensorChartApi?.data?.chart_array.map(item => item.date)
        let chartDataSet = {
            labels,
            datasets: [
                {
                    label: tab?.name,
                    data: sensorChartApi?.data?.chart_array.map(item => item.value),
                    borderColor: (context) => {
                        const chart = context.chart;
                        const { ctx, chartArea } = chart;

                        if (!chartArea) {
                            return null;
                        }

                        const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                        gradient.addColorStop(0, '#30795E');
                        gradient.addColorStop(1, '#1E98E8');

                        return gradient;
                    },
                    pointRadius: 0,
                    borderWidth: 5,
                }
            ]
        }

        setChartData(chartDataSet);
    }, [sensorChartApi]);

    return (
        <ContentsSection
            header={true}
            footer={true}
            addClass={"gBg"}
        >
            <LeftMenu headTitle={`<div class="title">ID <span class="green">${id}</span></div>`}/>
            <div className="contents">
                <Header/>
                <div className="contentsNoBox">
                    <div className="leftMenu">
                        <div className="pcLeftMenu sub">
                            <div className="inputBox">
                                <div className="menuTitleBox"><div className="title">ID <span className="green">{id}</span></div></div>
                                <InputItemBox
                                    inputType=""
                                    addClass=""
                                    inputName="센서 유형"
                                    value={sensorApi?.data?.sensor?.category ? sensorApi?.data?.sensor?.category : '-'}
                                    func={(value)=>{}}
                                    disabled={true}
                                />
                                <InputItemBox
                                    inputType=""
                                    addClass=""
                                    inputName="센서 이름"
                                    value={sensorApi?.data?.sensor?.name ? sensorApi?.data?.sensor?.name : '-'}
                                    func={(value)=>{}}
                                    disabled={true}
                                />
                                <InputItemBox
                                    inputType=""
                                    addClass=""
                                    inputName="센서 위치 (위도/경도)"
                                    value={sensorApi?.data?.sensor?.lat && sensorApi?.data?.sensor?.lng ? (sensorApi?.data?.sensor?.lat + ' / ' + sensorApi?.data?.sensor?.lng) : '-'}
                                    func={(value)=>{}}
                                    disabled={true}
                                />
                                <InputItemBox
                                    inputType=""
                                    addClass=""
                                    inputName="센서 배터리"
                                    value={sensorApi?.data?.sensor?.battery ? sensorApi?.data?.sensor?.battery : '-'}
                                    func={(value)=>{}}
                                    disabled={true}
                                />
                                <InputItemBox
                                    inputType=""
                                    addClass=""
                                    inputName="센서 최근 계측시간"
                                    value={sensorApi?.data?.sensor?.created_at ? sensorApi?.data?.sensor?.created_at : '-'}
                                    func={(value)=>{}}
                                    disabled={true}
                                />
                                <CustomSelect
                                    inputType=""
                                    addClass="nonDropdown"
                                    inputName="센서 상태"
                                    value={sensorApi?.data?.sensor?.status}
                                    func={(name, value)=>{}}
                                    valKey="id"
                                    nameKey="type"
                                    disabled={true}
                                    options={[
                                        {id: "0", type: `<div class="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>정상</div>`},
                                        {id: "1", type: `<div class="status green"><img src="/assets/images/basic/GreenDot.svg"/>점검필요</div>`},
                                        {id: "10", type: `<div class="status gray"><img src="/assets/images/basic/GrayDot.svg"/>측정불가</div>`},
                                    ]}
                                />
                            </div>
                        </div>
                        <div className="mLeftMenu sub">
                            <div className="contentsItem">
                                <div className="inputBox">
                                    <div className="menuTitleBox"><div className="title">ID <span className="green">{id}</span></div></div>
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="센서 유형"
                                        value={sensorApi?.data?.sensor?.category ? sensorApi?.data?.sensor?.category : '-'}
                                        func={(value)=>{}}
                                        disabled={true}
                                    />
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="센서 이름"
                                        value={sensorApi?.data?.sensor?.name ? sensorApi?.data?.sensor?.name : '-'}
                                        func={(value)=>{}}
                                        disabled={true}
                                    />
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="센서 위치 (위도/경도)"
                                        value={sensorApi?.data?.sensor?.lat && sensorApi?.data?.sensor?.lng ? (sensorApi?.data?.sensor?.lat + ' / ' + sensorApi?.data?.sensor?.lng) : '-'}
                                        func={(value)=>{}}
                                        disabled={true}
                                    />
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="센서 배터리"
                                        value={sensorApi?.data?.sensor?.battery ? sensorApi?.data?.sensor?.battery : '-'}
                                        func={(value)=>{}}
                                        disabled={true}
                                    />
                                    <InputItemBox
                                        inputType=""
                                        addClass=""
                                        inputName="센서 최근 계측시간"
                                        value={sensorApi?.data?.sensor?.created_at ? sensorApi?.data?.sensor?.created_at : '-'}
                                        func={(value)=>{}}
                                        disabled={true}
                                    />
                                    <CustomSelect
                                        inputType=""
                                        addClass="nonDropdown"
                                        inputName="센서 상태"
                                        value={sensorApi?.data?.sensor?.status}
                                        func={(name, value)=>{}}
                                        valKey="id"
                                        nameKey="type"
                                        disabled={true}
                                        options={[
                                            {id: "0", type: `<div class="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>정상</div>`},
                                            {id: "1", type: `<div class="status green"><img src="/assets/images/basic/GreenDot.svg"/>점검 필요</div>`},
                                            {id: "10", type: `<div class="status gray"><img src="/assets/images/basic/GrayDot.svg"/>측정 불가</div>`},
                                        ]}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="subContents">
                        <div className="cardBox">
                            {sensorApi?.data?.sensor_data_details?.map((dataDetail, dataDetailKey) => (
                                <>
                                    <div className={`card pcCard ${tab?.code === dataDetail?.code ? 'active' : ''}`} onClick={() => setTab({"code": dataDetail?.code, "name": dataDetail?.name})}>
                                        <div className="cardTitle">
                                            <div>{dataDetail?.name}</div>
                                            <div>{dataDetail?.value_diff >= 0 ? ('+ ' + dataDetail?.value_diff) : dataDetail?.value_diff} {dataDetail?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                                        </div>
                                        <div className="cardContents">{dataDetail?.value} {dataDetail?.unit}</div>
                                    </div>
                                    <div className={`card mCard ${tab?.code === dataDetail?.code ? 'active' : ''}`} onClick={() => setTab({"code": dataDetail?.code, "name": dataDetail?.name})}>
                                        <div className="cardTitle">
                                            <div>{dataDetail?.name}</div>
                                            <div>{dataDetail?.value} {dataDetail?.unit}</div>
                                        </div>
                                        <div className="cardContents">{dataDetail?.value_diff >= 0 ? ('+ ' + dataDetail?.value_diff) : dataDetail?.value_diff} {dataDetail?.unit} <img src="/assets/images/icon/ArrowRise.svg"/></div>
                                    </div>
                                </>
                            ))}
                        </div>
                        <div className="subBox">
                            <MenuTitle
                                title={<><span className="green">{tab?.name}</span> 측정 그래프</>}
                                useSelectType={true}
                                selectType={chartType}
                                setSelectType={setChartType}
                                useDateRange={true}
                                titleAddClass={'mShow'}
                                buttonBoxAddClass={'nonMbtn'}
                                beginDate={startDate}
                                setBeginDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}/>
                            <div className="lineChartArea">
                                <div className="lineChartBox">
                                    <Line options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        plugins: {
                                            legend: {display: false},
                                            title: {display: false},
                                            annotation: {
                                                annotations: {}
                                            }
                                        },
                                        scales: {
                                            x: {
                                                grid: {
                                                    color: '#FFFFFF33', // X축의 grid line 색상
                                                },
                                                ticks: {
                                                    color: '#F1F1F1', // Y축 레이블 색상
                                                    padding: 15
                                                },
                                            },
                                            y: {
                                                grid: {
                                                    color: '#FFFFFF33', // Y축의 grid line 색상
                                                },
                                                ticks: {
                                                    color: '#F1F1F1', // Y축 레이블 색상
                                                    padding: 15
                                                },
                                            },
                                        },
                                    }} data={chartData}/>
                                </div>
                            </div>
                        </div>
                        <div className="subBox">
                            <div className="menuTitleBox"><div className="title mShow"><span className="green">{tab?.name}</span> 측정 로그</div></div>
                            <div className="paginationTable">
                                <TableSection
                                    headType={true}
                                    headAddClass="pcContents"
                                    th={[
                                        {style:{minWidth:"10%",width:"10%"}, contents:`센서 ID`},
                                        {style:{minWidth:"15%",width:"15%"}, contents:`센서 이름`},
                                        {style:{minWidth:"10%",width:"10%"}, contents:`센서 배터리`},
                                        {style:{minWidth:"15%",width:"15%"}, contents:`센서 최근 계측시간`},
                                        {style:{minWidth:"15%",width:"15%"}, contents:tab?.code},
                                        {style:{minWidth:"15%",width:"15%"}, contents:tab?.code + ` 변경 계측값`},
                                        {style:{minWidth:"15%",width:"15%"}, contents:`센서 상태`},
                                    ]}
                                >
                                    {sensorApi?.data?.sensor_values?.data?.map((sensor, sensorKey) => (
                                        <Fragment key={sensorKey}>
                                            <TableTr addClass={`pcContents`} style={{ cursor: "pointer" }} func={() => {}} mouseEnterFunc={() => {  }} mouseLeaveFunc={() => {  }}>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{sensor?.id}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>{sensor?.name}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{sensor?.battery}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>{sensor?.created_at}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>{sensor?.value}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>{sensor?.value_diff}</TableTd>
                                                <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>
                                                    {sensor?.status === 0 && (<div className="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>정상</div>)}
                                                    {sensor?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>점검 필요</div>)}
                                                    {sensor?.status === 10 && (<div className="status gray"><img src="/assets/images/basic/GrayDot.svg"/>측정 불가</div>)}
                                                </TableTd>
                                            </TableTr>
                                            <TableTr addClass={`mContents`} func={() => {}} mouseEnterFunc={() => {}} mouseLeaveFunc={() => {}}>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">센서 ID</div>
                                                    <div className="tableValue">{sensor?.id}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">센서 이름</div>
                                                    <div className="tableValue">{sensor?.name}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">센서 배터리</div>
                                                    <div className="tableValue">{sensor?.battery}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">센서 최근 계측시간</div>
                                                    <div className="tableValue">{sensor?.login_id}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">{tab?.code}</div>
                                                    <div className="tableValue">{sensor?.value}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">{tab?.code} 변경 계측값</div>
                                                    <div className="tableValue">{sensor?.value_diff}</div>
                                                </TableTd>
                                                <TableTd addClass="">
                                                    <div className="tableLabel">센서 상태</div>
                                                    <div className="tableValue">
                                                        {sensor?.status === 0 && (<div className="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>정상</div>)}
                                                        {sensor?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>점검 필요</div>)}
                                                        {sensor?.status === 10 && (<div className="status gray"><img src="/assets/images/basic/GrayDot.svg"/>측정 불가</div>)}
                                                    </div>
                                                </TableTd>
                                            </TableTr>
                                        </Fragment>
                                    ))}
                                </TableSection>
                                {sensorApi ? <PageNation perPage={5} total={sensorApi?.data?.sensor_values?.last_page} page={page} func={(i)=>{setPage(i)}}/> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ContentsSection>
    );
};

export default Detail;
import useGet from "api/useGet";
import React, { useState } from "react";
import {BottomErrMsg, CustomPopup, LoadingBox} from "component/basic/popup";
import { InputBoxCol2, InputItemBox } from "component/basic/formItems";
import * as fatchSet from "../../../api/api";

const Index = (props) => {
    const [password, setPassword] =  useState('');
    const [passwordConfirm, setPasswordConfirm] =  useState('');

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const accountApi = useGet({url:`/account`, loginType:"login"});

    const handleModify = async () => {
        if (password !== passwordConfirm) {
            setBottomMsgData({
                text : "새 비밀번호 값이 일치하지 않습니다.",
                chk : bottomMsgData.chk + 1
            });
            return false
        }

        const formdata = new FormData();
        formdata.append("password", password);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formdata,
            url: "/password/modify",
            loginType: "login",
            success: (data) => {
                setBottomMsgData({
                    text : "변경되었습니다",
                    chk : bottomMsgData.chk + 1
                });
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    };

    return (
        <div className="contentsItem">
            <div className="inputBox">
                <InputItemBox
                    inputType={null}
                    addClass=""
                    inputName="아이디"
                    placeholder="아이디를 입력해주세요"
                    value={accountApi?.data?.user_info?.login_id}
                    max={null}
                    disabled={true}
                />
                <InputBoxCol2>
                    <InputItemBox
                        inputType="password"
                        addClass=""
                        inputName="새 비밀번호"
                        placeholder="새 비밀번호를 입력해주세요"
                        value={password}
                        func={(value)=>{setPassword(value)}}
                    />
                    <InputItemBox
                        inputType="password"
                        addClass=""
                        inputName="새 비밀번호 확인"
                        placeholder="새 비밀번호 확인을 입력해주세요"
                        value={passwordConfirm}
                        func={(value)=>{setPasswordConfirm(value)}}
                    />
                </InputBoxCol2>
            </div>
            <div className="buttonBox">
                <button className="primaryButton" onClick={() => { handleModify() }}>저장하기</button>
            </div>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </div>
    );
};

export default Index;
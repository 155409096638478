import React, {useRef, forwardRef, useImperativeHandle, useState, useEffect} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {Radar} from "react-chartjs-2";
import { Chart as ChartJS, RadialLinearScale, CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import useGet from "../../api/useGet";

const OverallPdfDownload = forwardRef((props, ref) => {
    const hiddenRef = useRef();
    const [downloadId, setDownloadId] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [data, setData] = useState({
        labels: ["Vitality", "Irrigation", "Damage", "Soil", "Safety", "Pruning"],
        datasets: [
            {
                label: "Scores",
                data: [0, 0, 0, 0, 0, 0],
                borderColor: "#6FD195",
                borderWidth: 2,
                pointRadius: 0,
                backgroundColor: 'rgba(111, 209, 149, 0.3)',
            },
        ],
    });

    ChartJS.register(RadialLinearScale, CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

    const reviewData = useGet({url:`/review/detail?review_id=${downloadId}`, loginType:"login"});

    useEffect(() => {
        if (reviewData) {
            setData({
                labels: ["Vitality", "Irrigation", "Damage", "Soil", "Safety", "Pruning"],
                datasets: [
                    {
                        label: "Scores",
                        data: [reviewData?.data?.review?.vitality_score, reviewData?.data?.review?.irrigation_score, reviewData?.data?.review?.damage_score, reviewData?.data?.review?.soil_score, reviewData?.data?.review?.safety_score, reviewData?.data?.review?.pruning_score],
                        borderColor: "#6FD195",
                        borderWidth: 2,
                        pointRadius: 0,
                        backgroundColor: 'rgba(111, 209, 149, 0.3)',
                    },
                ],
            })
        }
    }, [reviewData]);

    const options = {
        scales: {
            r: {
                angleLines: {
                    display: true,
                },
                suggestedMin: 0,
                suggestedMax: 100,
                pointLabels: {
                    font: {
                        size: 48,
                    },
                    color: '#000',
                },
                ticks: {
                    stepSize: 20,
                    backdropColor: 'rgba(0, 0, 0, 0)',
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    useImperativeHandle(ref, () => ({
        downloadPdf: (selectDownloadId) => {
            setIsVisible(true)
            setDownloadId(selectDownloadId)
            props?.setLoading(true)
            setTimeout(() => {
                const input = hiddenRef.current;
                html2canvas(input, { useCORS: true, logging: true })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/png");
                        const pdf = new jsPDF("p", "mm", "a4");

                        const imgWidth = 210;
                        const pageHeight = 295;
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;
                        let heightLeft = imgHeight;
                        let position = 0;

                        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;

                        while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            pdf.addPage();
                            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                            heightLeft -= pageHeight;
                        }

                        pdf.save("download.pdf");
                    })
                    .catch((error) => {
                        console.log(error)
                    });
                props?.setLoading(false)
                setIsVisible(false)
            }, 3000)
        }
    }));

    return (
        <div className="pdfBox" style={{ display: isVisible ? 'grid' : 'none'}} ref={hiddenRef}>
            <div className="pdfPage">
                <div className="pdfTop">
                    <div className="pdfHeader">
                        <div className="left"><b>OVERALL REVIEW</b> : {reviewData?.data?.review?.company_name}</div>
                        <div className="right"><img src="/assets/images/logo_b.png"/></div>
                    </div>
                    <div className="pdfHr"/>
                    <div className="pdfSubHeader">
                        <div className="pdfSubHeaderBox">
                            <div className="pdfSubHeaderItem">
                                <div>VITALITY SCORE</div>
                                <div>{reviewData?.data?.review?.vitality_score}</div>
                            </div>
                            <div className="pdfSubHeaderItem">
                                <div>IRRIGATION SCORE</div>
                                <div>{reviewData?.data?.review?.irrigation_score}</div>
                            </div>
                            <div className="pdfSubHeaderItem">
                                <div>DAMAGE SCORE</div>
                                <div>{reviewData?.data?.review?.damage_score}</div>
                            </div>
                        </div>
                        <div className="pdfSubHeaderBox">
                            <div className="pdfSubHeaderItem">
                                <div>SOIL SCORE</div>
                                <div>{reviewData?.data?.review?.soil_score}</div>
                            </div>
                            <div className="pdfSubHeaderItem">
                                <div>SAFETY SCORE</div>
                                <div>{reviewData?.data?.review?.safety_score}</div>
                            </div>
                            <div className="pdfSubHeaderItem">
                                <div>PRUNING SCORE</div>
                                <div>{reviewData?.data?.review?.pruning_score}</div>
                            </div>
                        </div>
                        <div className="pdfSubHeaderBox">
                            <div className="pdfSubHeaderItem">
                                <div>CREATED ON</div>
                                <div>{reviewData?.data?.review?.created_at}</div>
                            </div>
                        </div>
                    </div>
                    <div className="pdfChart">
                        <Radar data={data} options={options} />
                    </div>
                </div>
                <div className="pdfBottom">
                    <div className="pdfHeader">
                        <div className="left"><b>COMMENT</b></div>
                    </div>
                    <div className="pdfHr"/>
                    <div className="pdfCommentContents">
                        {reviewData?.data?.review?.report_comment}
                    </div>
                </div>
            </div>
        </div>
    );
});

export default OverallPdfDownload;
import React, {useRef, forwardRef, useImperativeHandle, useState, useEffect, Fragment} from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {Line, Radar} from "react-chartjs-2";
import { Chart as ChartJS, RadialLinearScale, CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import moment from "moment";
import useGet from "../../api/useGet";
import {TableSection, TableTd, TableTr} from "../basic";

const ReportPdfDownload = forwardRef((props, ref) => {
    const hiddenRef = useRef();
    const radarRef = useRef(null);
    const lineRef = useRef(null);

    const [downloadId, setDownloadId] = useState('');
    const [isVisible, setIsVisible] = useState(false);
    const [count, setCount] = useState(0);

    ChartJS.register(RadialLinearScale, CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

    const [time, setTime] =  useState('');
    const [startDate, setStartDate] =  useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] =  useState(moment().format('YYYY-MM-DD'));
    const [chartData, setChartData] =  useState({labels: [], datasets: [{label: "", data: []}],});

    const sensorChartApi = useGet({url:downloadId ? `/sensor/report/log?sensor_id=${downloadId}&start_date=${startDate}&end_date=${endDate}&time=${time}` : '', loginType:"login"});

    useEffect(() => {
        return () => {
            if (radarRef.current) {
                radarRef.current.destroy();
            }
            if (lineRef.current) {
                lineRef.current.destroy();
            }
        };
    }, []);

    useEffect(() => {
        if (sensorChartApi?.data?.sensor_data_details?.length > 0) {
            setCount(Math.round(sensorChartApi?.data?.sensor_data_details?.length / 2))
        } else {
            setCount(0)
        }
    }, [sensorChartApi]);

    useImperativeHandle(ref, () => ({
        downloadPdf: (selectDownloadId, selectStartDate, selectEndDate, selectTime) => {
            setIsVisible(true)
            setDownloadId(selectDownloadId)
            if (selectStartDate !== '') {
                setStartDate(selectStartDate)
            }
            if (selectEndDate !== '') {
                setEndDate(selectEndDate)
            }
            if (selectTime !== '') {
                setTime(selectTime)
            }
            props?.setLoading(true)

            setTimeout(() => {
                const input = hiddenRef.current;
                html2canvas(input, { useCORS: true, logging: true })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/png");
                        const pdf = new jsPDF("p", "mm", "a4");

                        const imgWidth = 210;
                        const pageHeight = 295;
                        const imgHeight = (canvas.height * imgWidth) / canvas.width;
                        let heightLeft = imgHeight;
                        let position = 0;

                        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;

                        while (heightLeft >= 0) {
                            position = heightLeft - imgHeight;
                            pdf.addPage();
                            pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
                            heightLeft -= pageHeight;
                        }

                        pdf.save("download.pdf");
                        props?.setLoading(false)
                        setIsVisible(false)
                    })
                    .catch((error) => {
                        console.log(error)
                        props?.setLoading(false)
                        setIsVisible(false)
                    });
            }, 3000)
        }
    }));

    return (
        <div className="pdfBox"  style={{ display: isVisible ? 'grid' : 'none'}} ref={hiddenRef}>
            {Array.from({ length: count }).map((_, index) => (
                <div className="pdfPage">
                    <div className="pdfTop">
                        <div className="pdfHeader">
                            <div className="left"><b>TREE SENSOR REPORT</b> : {sensorChartApi?.data?.sensor?.company_name}</div>
                            <div className="right"><img src="/assets/images/logo_b.png"/></div>
                        </div>
                        <div className="pdfHr"/>
                        <div className="pdfSubHeader">
                            <div className="pdfSubHeaderBox">
                                <div className="pdfSubHeaderItem">
                                    <div>SENSOR ID</div>
                                    <div>{sensorChartApi?.data?.sensor?.id}</div>
                                </div>
                                <div className="pdfSubHeaderItem">
                                    <div>SENSOR NAME</div>
                                    <div>{sensorChartApi?.data?.sensor?.name}</div>
                                </div>
                            </div>
                            <div className="pdfSubHeaderBox">
                                <div className="pdfSubHeaderItem">
                                    <div>SENSOR LOCATION (Latitude)</div>
                                    <div>{sensorChartApi?.data?.sensor?.lat ? sensorChartApi?.data?.sensor?.lat : '-'}</div>
                                </div>
                                <div className="pdfSubHeaderItem">
                                    <div>SENSOR LOCATION (Longitude)</div>
                                    <div>{sensorChartApi?.data?.sensor?.lng ? sensorChartApi?.data?.sensor?.lng : '-'}</div>
                                </div>
                            </div>
                            <div className="pdfSubHeaderBox">
                                <div className="pdfSubHeaderItem">
                                    <div>CREATED ON</div>
                                    <div>{sensorChartApi?.data?.sensor?.created_at ? sensorChartApi?.data?.sensor?.created_at : '-'}</div>
                                </div>
                                <div className="pdfSubHeaderItem">
                                    <div>PERIOD</div>
                                    <div>{startDate} ~ {endDate}</div>
                                </div>
                            </div>
                        </div>
                        <div className="pdfLineChart">
                            <div className="pdfLineChartMenu">
                                <div className="left"><div className="green">{sensorChartApi?.data?.sensor_data_details[(index + 1) * 2 - 2]?.name}</div> 측정 그래프</div>
                                <div className="right">
                                    <div>{startDate} ~ {endDate}</div>
                                    <div className="line">ㅣ</div>
                                    <div>시간 기준</div>
                                </div>
                            </div>
                            <Line ref={lineRef} height={800} options={{
                                responsive: true,
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {display: false},
                                    title: {display: false},
                                    annotation: {
                                        annotations: {}
                                    }
                                },
                                scales: {
                                    x: {
                                        grid: {
                                            color: '#00000033', // X축의 grid line 색상
                                        },
                                        ticks: {
                                            color: '#000', // Y축 레이블 색상
                                            padding: 15
                                        },
                                    },
                                    y: {
                                        grid: {
                                            color: '#00000033', // Y축의 grid line 색상
                                        },
                                        ticks: {
                                            color: '#000', // Y축 레이블 색상
                                            padding: 15
                                        },
                                    },
                                },
                            }} data={{
                                labels: sensorChartApi?.data?.log_array?.map(item => item.date),
                                datasets: [
                                    {
                                        label: '',
                                        data: sensorChartApi?.data?.log_array?.map(item => item[sensorChartApi?.data?.sensor_data_details[(index + 1) * 2 - 2]?.code]),
                                        borderColor: (context) => {
                                            const chart = context.chart;
                                            const { ctx, chartArea } = chart;

                                            if (!chartArea) {
                                                return null;
                                            }

                                            const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                                            gradient.addColorStop(0, '#30795E');
                                            gradient.addColorStop(1, '#1E98E8');

                                            return gradient;
                                        },
                                        pointRadius: 0,
                                        borderWidth: 5,
                                    }
                                ]
                            }}/>
                        </div>
                        <div className="pdfDotHr"/>
                        {sensorChartApi?.data?.sensor_data_details[(index + 1) * 2] && (
                            <div className="pdfLineChart">
                                <div className="pdfLineChartMenu">
                                    <div className="left"><div className="green">{sensorChartApi?.data?.sensor_data_details[(index + 1) * 2 - 1]?.name}</div> 측정 그래프</div>
                                    <div className="right">
                                        <div>{startDate} ~ {endDate}</div>
                                        <div className="line">ㅣ</div>
                                        <div>시간 기준</div>
                                    </div>
                                </div>
                                <Line ref={lineRef} height={800} options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {display: false},
                                        title: {display: false},
                                        annotation: {
                                            annotations: {}
                                        }
                                    },
                                    scales: {
                                        x: {
                                            grid: {
                                                color: '#00000033', // X축의 grid line 색상
                                            },
                                            ticks: {
                                                color: '#000', // Y축 레이블 색상
                                                padding: 15
                                            },
                                        },
                                        y: {
                                            grid: {
                                                color: '#00000033', // Y축의 grid line 색상
                                            },
                                            ticks: {
                                                color: '#000', // Y축 레이블 색상
                                                padding: 15
                                            },
                                        },
                                    },
                                }} data={{
                                    labels: sensorChartApi?.data?.log_array?.map(item => item.date),
                                    datasets: [
                                        {
                                            label: '',
                                            data: sensorChartApi?.data?.log_array?.map(item => item[sensorChartApi?.data?.sensor_data_details[(index + 1) * 2 - 1]?.code]),
                                            borderColor: (context) => {
                                                const chart = context.chart;
                                                const { ctx, chartArea } = chart;

                                                if (!chartArea) {
                                                    return null;
                                                }

                                                const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
                                                gradient.addColorStop(0, '#30795E');
                                                gradient.addColorStop(1, '#1E98E8');

                                                return gradient;
                                            },
                                            pointRadius: 0,
                                            borderWidth: 5,
                                        }
                                    ]
                                }}/>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <div className="pdfPage" style={{ marginTop: 100 }}>
                <div className="pdfTop">
                    <div className="pdfHeader">
                        <div className="left"><b>OVERALL REVIEW</b> : Xi Spring County : B-101</div>
                        <div className="right"><img src="/assets/images/logo_b.png"/></div>
                    </div>
                    <div className="pdfHr"/>
                    <div className="pdfSubHeader">
                        <div className="pdfSubHeaderBox">
                            <div className="pdfSubHeaderItem">
                                <div>SENSOR ID</div>
                                <div>{sensorChartApi?.data?.sensor?.id}</div>
                            </div>
                            <div className="pdfSubHeaderItem">
                                <div>SENSOR NAME</div>
                                <div>{sensorChartApi?.data?.sensor?.name}</div>
                            </div>
                        </div>
                        <div className="pdfSubHeaderBox">
                            <div className="pdfSubHeaderItem">
                                <div>SENSOR LOCATION (Latitude)</div>
                                <div>{sensorChartApi?.data?.sensor?.lat ? sensorChartApi?.data?.sensor?.lat : '-'}</div>
                            </div>
                            <div className="pdfSubHeaderItem">
                                <div>SENSOR LOCATION (Longitude)</div>
                                <div>{sensorChartApi?.data?.sensor?.lng ? sensorChartApi?.data?.sensor?.lng : '-'}</div>
                            </div>
                        </div>
                        <div className="pdfSubHeaderBox">
                            <div className="pdfSubHeaderItem">
                                <div>CREATED ON</div>
                                <div>{sensorChartApi?.data?.sensor?.created_at ? sensorChartApi?.data?.sensor?.created_at : '-'}</div>
                            </div>
                            <div className="pdfSubHeaderItem">
                                <div>PERIOD</div>
                                <div>{startDate} ~ {endDate}</div>
                            </div>
                        </div>
                    </div>
                    <TableSection
                        addClass="pdf"
                        headTitle="센서 계측 로그"
                        headType={true}
                        headAddClass="pcContents"
                        th={getTh(sensorChartApi?.data?.sensor_data_details)}
                    >
                        {sensorChartApi?.data?.log_array?.map((sensor, sensorKey) => (
                            <Fragment key={sensorKey}>
                                <TableTr addClass={`pcContents`} style={{ cursor: "pointer" }} func={() => {}} mouseEnterFunc={() => {  }} mouseLeaveFunc={() => {  }}>
                                    <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{sensor?.sensor_id}</TableTd>
                                    <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>{sensor?.name}</TableTd>
                                    <TableTd addClass="alignLeft" style={{ minWidth:"10%", width:"10%" }}>{sensor["Battery Percent"]}</TableTd>
                                    <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>{sensor["date"]}</TableTd>
                                    {sensorChartApi?.data?.sensor_data_details?.map((dataDetail, dataDetailKey) => {
                                        if (dataDetail?.code != "Battery Percent") {
                                            return <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>{sensor[dataDetail?.code]}</TableTd>
                                        }
                                    })}
                                    <TableTd addClass="alignLeft" style={{ minWidth:"15%", width:"15%" }}>
                                        {sensor?.status === 0 && (<div className="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>진행중</div>)}
                                        {sensor?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>완료</div>)}
                                        {sensor?.status === 10 && (<div className="status gray"><img src="/assets/images/basic/GrayDot.svg"/>보류</div>)}
                                    </TableTd>
                                </TableTr>
                                <TableTr addClass={`mContents`} func={() => {}} mouseEnterFunc={() => {}} mouseLeaveFunc={() => {}}>
                                    <TableTd addClass="">
                                        <div className="tableLabel">센서 ID</div>
                                        <div className="tableValue">{sensor?.sensor_id}</div>
                                    </TableTd>
                                    <TableTd addClass="">
                                        <div className="tableLabel">센서 이름</div>
                                        <div className="tableValue">{sensor?.name}</div>
                                    </TableTd>
                                    <TableTd addClass="">
                                        <div className="tableLabel">센서 배터리</div>
                                        <div className="tableValue">{sensor?.battery}</div>
                                    </TableTd>
                                    <TableTd addClass="">
                                        <div className="tableLabel">센서 최근 계측시간</div>
                                        <div className="tableValue">{sensor?.login_id}</div>
                                    </TableTd>
                                    <TableTd addClass="">
                                        <div className="tableLabel">전기 전도도</div>
                                        <div className="tableValue">{sensor?.vitality}</div>
                                    </TableTd>
                                    <TableTd addClass="">
                                        <div className="tableLabel">전기 전도도 변경 계측값</div>
                                        <div className="tableValue">{sensor?.bark_temperature}</div>
                                    </TableTd>
                                    <TableTd addClass="">
                                        <div className="tableLabel">센서 상태</div>
                                        <div className="tableValue">
                                            {sensor?.status === 0 && (<div className="status yellow"><img src="/assets/images/basic/YellowDot.svg"/>진행중</div>)}
                                            {sensor?.status === 1 && (<div className="status green"><img src="/assets/images/basic/GreenDot.svg"/>완료</div>)}
                                            {sensor?.status === 10 && (<div className="status gray"><img src="/assets/images/basic/GrayDot.svg"/>보류</div>)}
                                        </div>
                                    </TableTd>
                                </TableTr>
                            </Fragment>
                        ))}
                    </TableSection>
                </div>
            </div>
        </div>
    );
});

export default ReportPdfDownload;

function getTh(sensorDataDetails) {
    const th = [];
    th.push({style:{minWidth:"10%",width:"10%"}, contents:`센서 ID`},
        {style:{minWidth:"15%",width:"15%"}, contents:`센서 이름`},
        {style:{minWidth:"10%",width:"10%"}, contents:`센서 배터리`},
        {style:{minWidth:"15%",width:"15%"}, contents:`센서 최근 계측시간`})

    sensorDataDetails?.map((data, key) => {
        if (data?.code !== "Battery Percent") {
            th.push({style:{minWidth:"15%",width:"15%"}, contents: data?.name})
        }
    })

    return th
}
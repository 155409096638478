import useGet from "api/useGet";
import { ChannelShowMessenger } from "component/app/channelTalk";
import { bodyScroll_clear, bodyScroll_limit } from "js/function";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function PcheaderSubMenuArea(data){
    return (
        <div className="pcheader_subMenu_area">
            {data.children}
        </div>
    );
}

function PcheaderSubMenuItem(data){
    const { pathname } = useLocation();

    return (
        <button type="button" onClick={()=>{data.func()}} className={`pcheader_subMenu_item ${data.pathname && pathname === data.pathname ? "active" : ""} ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function HeaderLoginLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`header_loginLink ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function HeaderSubLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`header_subLink ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false}>
            {data.children}
        </button>
    );
}

function HeaderLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`header_link ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function SideMenuLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`sideMenu_link ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.contents}}/>
    );
}

function SideMenuSubLink(data){
    return (
        <button type="button" onClick={()=>{data.func()}} className={`sideMenu_subLink ${data.addClass ? data.addClass : ""}`} disabled={data.disabled ? data.disabled : false}>
            {data.children}
        </button>
    );
}

{/*
<Header
    subLinkType="market"//care
    addClass=""
    mMenu={true}//false
    logo={true}//false
    pageBack={true}//false
    func={()=>{}}
    headTitle=""
    rightBtnsArr={[
        {
            addClass:"",
            func:()=>{},
            contents:""
        },
        {
            addClass:"",
            func:()=>{},
            contents:""
        }
    ]}//null
    closeFunc={()=>{}}
    pageClose={true}//true
/>
*/}

function Header(headerData){
    let navigate = useNavigate();
    const [notificationMenu,setNotificationMenu] = useState(false);
    const [pcSubMenu,setPcSubMenu] = useState(false);

    const headerApi = useGet({
        url:`/header`,
        loginType:"login"
    });
    const headerApiData = headerApi?.data?.company_info;

    const alarmApi = useGet({url: notificationMenu ? `/alarm` : '', loginType:"login"});

    return (
        <div className="header">
            <div className="pcHeader">
                <div className="headerSection">
                    <div className="headerArea headerArea_left">
                        <div className="title">{headerApiData?.name}</div>
                    </div>
                    <div className="headerArea headerArea_right">
                        <button className={`alarmButton ${headerApiData?.alarm_check === 1 ? 'active' : ''}`} type="button" onClick={() => { setNotificationMenu(!notificationMenu); setPcSubMenu(false) }}><img src="/assets/images/icon/alarm.svg"/></button>
                        <button type="button" className="header_subMenu_openBtn" onClick={() => { setPcSubMenu(!pcSubMenu); setNotificationMenu(false) }}>
                            <p className="header_userName">{headerApiData?.user_name}</p>
                            <img src="/assets/images/icon/user_menu_icon.svg" className="header_subMenu_openBtn_icon"/>
                        </button>
                    </div>
                    <div className={`pcheader_subMenu ${notificationMenu ? "active" : ""}`}>
                        <div className="pcheader_subMenu_card_box">
                            <div className="pcheader_subMenu_card">
                                <div className="pcheader_subMenu_title">Notification</div>
                            </div>
                            {alarmApi?.data?.alarms?.map((alarm) => (
                                <div className="pcheader_subMenu_card">
                                    <div className="pcheader_subMenu_card_title">{alarm?.status === 0 && <img src="/assets/images/icon/redDot.svg"/>} {alarm?.title}</div>
                                    <div className="pcheader_subMenu_card_contents">{alarm?.contents}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`pcheader_subMenu small ${pcSubMenu ? "active" : ""}`}>
                        <div className="pcheader_subMenu_card_box">
                            <PcheaderSubMenuArea>
                                <PcheaderSubMenuItem
                                    pathname="/logout"
                                    contents="로그아웃"
                                    func={()=>{window.logOut();setPcSubMenu(false)}}
                                />
                                <PcheaderSubMenuItem
                                    pathname="/settings/account"
                                    contents="설정"
                                    func={()=>{navigate("/settings/account");setPcSubMenu(false)}}
                                />
                            </PcheaderSubMenuArea>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mHeader">{headerApiData?.name}</div>
        </div>
    );
}

export {Header};
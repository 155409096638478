import Private from "pages/private/Private";
import PublicOnly from "pages/private/PublicOnly";
import React, { useEffect, useState } from "react";
import * as fatchSet from "../api/api";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "component/basic/scrollTopSet";
import { BottomErrMsg, LoadingBox, Popup, RightPopup } from "component/basic/popup";
import useGet from "api/useGet";
import { ErrorBoundary } from "react-error-boundary";
import { routes } from "./routes";
import { EditerBox, PageSizing } from "component/app/items";
import { BtnBox, BtnItem } from "component/basic/btns";
import { ChannelTalkLoad } from "component/app/channelTalk";

const Router = () => {
    const [token,setToken] = useState(localStorage.getItem("token") ? true : false);
    const [familyId,setFamilyId] =  useState(sessionStorage.getItem("familyId") ? sessionStorage.getItem("familyId") : null);
    const [terms,setTerms] =  useState("");
    
    const [rightOpenType,setRightOpenType] =  useState(false);
    const [popupData,setPopupData] =  useState(null);
    const [loadingBoxOpen,setLoadingBoxOpen] =  useState(false);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    window.logIn = function(){
        setToken(true);
    }
    window.logOut = function(){
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");

        localStorage.removeItem("companyId")

        sessionStorage.removeItem("familyId");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        sessionStorage.removeItem("headerSubBanner");

        setToken(false);

        window.location.href = "/login";
    }

    window.familyIdSetting = function(id){
        if(id == "my"){
            sessionStorage.removeItem("familyId")
            setFamilyId(null);
        }else{
            sessionStorage.setItem("familyId",id)
            setFamilyId(id);
        }
    }

    window.termsOpen = (id,title,contents,btnType) => {
        let termsItem = {
            id:id,
            title:title,
            contents:contents,
            btnType:btnType
        }
        setTerms(termsItem);
        setRightOpenType(true);
    }

    window.errPopupOpen = (data) => {
        if(data.data || data.alert){
            setPopupData({
                addClass:null,
                title:data.alert||"",
                text:data.data||"",
                closeType:true,
                closeFunc:(e) => {},
                btnFunc0:(e)=>{},
                btn0:"확인",
                btn0Type:"",
                btn1:"",
                btn1Type:"",
                btnFunc1:()=>{}
            });
        }
    }

    window.errMsg = (msg) => {
        if(msg){
            setBottomMsgData({
                text : msg,
                chk : bottomMsgData.chk + 1
            });
        }
    }

    window.loadingBox = (type) => {
        setLoadingBoxOpen(type);
    }

    useEffect(()=>{
        
    },[]);

    //백화 시 노출
    const ErrorFallback = (err) => {
        console.log(err)
        return (
            <div className="pageSizing">
                <div className="pageErrBox">
                    <h1 className="pageErrTitle">서비스 이용에 불편을 드려 죄송합니다.</h1>
                    <p className="pageErrText">서비스 오류가 발생했습니다.<br/>잠시 후 다시 시도해주세요</p>
                </div>
                <div className="pageBtn_box">
                    <button type="button" className="pageBtn" onClick={() => window.location.href = "/"}>처음으로</button>
                </div>
            </div>
        );
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BrowserRouter>
                <ScrollToTop/>
                <PageSizing>
                <Routes>
                    {routes.map(
                        ({ exact, path, component, type }) => (
                            <Route
                                key={`public-route-${path}`}
                                exact={exact}
                                path={path}
                                element={type == "login" ? 
                                    token ? component : <Private/>
                                : type == "notLogin" ?  
                                    token ? <PublicOnly/> : component
                                : component}
                            />
                        )
                    )}

                    {/*404*/}
                    <Route
                        key={`public-route-/*`}
                        exact={true}
                        path="/*"
                        element={<PublicOnly/>}
                    />
                </Routes>
                </PageSizing>            
                <RightPopup
                    topTitle={terms?.title}
                    addClass={rightOpenType ? `active ${terms?.btnType ? "bottomBtn" : ""}` : terms?.btnType ? "bottomBtn" : ""}
                    closePopup={() => {setTerms({});setRightOpenType(false)}}
                    openType={rightOpenType}
                    // bType={true}
                >
                    <EditerBox addClass="termsText" data={terms?.contents}/>
                    {terms?.btnType ? 
                        <BtnBox
                            boxType="fixed"
                            addClass=""
                        >
                            <BtnItem
                                addClass=""
                                contents={terms?.btnText}
                                disabled={false}
                                func={() => {terms?.btnFunc(terms?.id,terms?.title);setTerms({});setRightOpenType(false)}}
                            />
                        </BtnBox>
                    :""}
                </RightPopup>
                <ChannelTalkLoad/>
                <Popup
                    data={popupData}
                />
                <BottomErrMsg
                    text={bottomMsgData.text ? bottomMsgData.text : ""}
                    chk={bottomMsgData.chk}
                />
                <LoadingBox openType={loadingBoxOpen}/>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default Router;